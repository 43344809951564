<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>التصنيف والبحث
    </h3>
    <v-row align="center">
      <!-- QUERY -->

      <!-- Q -->
       <v-col class="d-flex" cols="12">
        <v-autocomplete
          rounded
          outlined
          hide-details
          clearable
          solo
          label="القسم"
          class="mb-5 mx-3"
          v-model="filters.section_id"
          :items="sections"
        ></v-autocomplete>
        <v-text-field
          rounded
          outlined
          solo
          hide-details
          clearable
          label="البحث بالاسم"
          v-model="filters.search"
        ></v-text-field>
      </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="12">
        <v-spacer></v-spacer>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { ...filters, page: 1 } })"
          large
          color="primary"
          >ابحث</v-btn
        >
            
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:0} })"
          large
          color="primary"
          >عرض الكل
        </v-btn>
        <v-btn
          class="mr-2"
          @click="$router.replace({ query: { page: 1 , active:1} })"
          @mouseover="fetchRecords(1)"
          large
          color="primary"
        >
          عرض النشط
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,
      section: "",
      filters: {
        search: null,
      },
    };
  },

  created() {
    if (this.sections.length<=0) {
      this.fetchSections();
    }
  },

  computed: {
    ...mapState({
      sections: (state) => state.filters.sections,
    }),
  },

  methods: {
    ...mapActions("filters", ["fetchSections"]),
    ...mapActions("categories", ["fetchRecords"]),
    ...mapMutations("categories", ["SET_FILTERS"]),
  },
};
</script>
