<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-monitor-dashboard</v-icon>
      ادارة الاقسام
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- CREATE / UPDATE FROM -->
    <record-form />

    <!-- UPDATE / NEW RECORD -->
    <filters-box @fetch="fetchRecords({ page: 1 })" />
    <div class="mb-3 mt-9 text-left">
      <v-btn
        large
        color="primary"
        elevation="0"
        @click="
          SET_FORM_RECORD({});
          SET_EDIT_MODE(false);
          SHOW_FORM();
        "
      >
       اضافة قسم جديد
        <v-icon class="mr-2">mdi-plus</v-icon>
      </v-btn>
    </div>
      <v-chip right class="mb-2" label color="secondary">
        <v-icon> mdi-account-circle-outline </v-icon> عدد الاصناف
        {{ total_categories }}</v-chip
      >
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="records"
      loading-text="Downloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer
    >
      <template v-slot:item.checkbox="{ item }">
        <v-checkbox v-model="item.selected" ></v-checkbox>
      </template>

    <template v-slot:item.icon="{ item }">
        <img :src="item.icon" width="50px" class="mt-3 mb-2 rounded-lg" />
      </template>
      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
          small
          icon
          color="secondary"
          class="mx-1"
          @click="
            SET_FORM_RECORD(item);
            SET_EDIT_MODE(true);
            SHOW_FORM();
          "
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.blocked="{ item }">
        <v-switch
          v-if="item.active == 0"
          inset
          :input-value="false"
          @change="unblock(item)"
        ></v-switch>
        <v-switch
          v-else="item.active == 1"
          color="success"
          inset
          :input-value="true"
          @change="block(item)"
        ></v-switch>
      </template>

      <template v-slot:top>
        <v-toolbar flat class="d-flex justify-end">
          <v-btn color="error" class="mr-1" @click="deleteSelected">حذف المحدد</v-btn>
        </v-toolbar>
      </template>


      <template v-slot:[`item.delete`]="{ item }">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on"> حذف </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="red" dark>حذف  التصنيف </v-toolbar>
              <v-card-text>
                <h4 class="my-3 red--text">هل تريد حذف هذا التصنيف</h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                    color="red"
                    dark
                    @click="deleteThisCategory(item.id), (dialog.value = false)"
                >
                  حذف
                </v-btn>
                <v-btn text @click="dialog.value = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
        total-visible="7"
        @input="$router.replace({ query: { ...$route.query, page: $event } })"
        :value="parseInt(filters.page)"
        :length="totalPages"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import {mapState, mapMutations, mapActions} from "vuex";
import FiltersBox from "./FiltersBox.vue";
export default {
  name: "categories",
  components: { RecordForm,  FiltersBox},

  data: function () {
    return {
      dialogDelete: false,
      // table
      table: {
        headers: [
          {
            text: "حذف",
            value: "checkbox",
          },
          {
            value: "name_ar",
            text: "الاسم بالعربية",
          },
          {
            value: "name_en",
            text: "الاسم بالانجليزية",
          },
          {
            value: "order",
            text: "الترتيب",
          },
          {
            value: "section",
            text: "القسم",
          },
          {
            value: "icon",
            text: "الصورة",
          },
          // {
          //   value: "controls",
          //   text: "اعدادات"
          // },
          {
            value: "blocked",
            text: "تفعيل/تعطيل",
          },
          {
            value: "controls",
            text: "تعديل",
          },
          {
            text: "حذف",
            value: "delete",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {
    ...mapState("categories", ["records", "page", "totalPages", "filters" , "total_categories"]),
    // ...mapState("admins", {
    //   tableRecords: (state) => state.records,
    //   paginationPage: (state) => state.page,
    //   paginationLength: (state) => state.totalPages,
    // }),
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({ ...route.query });
        this.fetchRecords(route.query);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations("categories", [
      "SHOW_FORM",
      "SET_EDIT_MODE",
      "SET_FORM_RECORD",

      "SHOW_RECORD_FORM",

      "SET_FILTERS",
    ]),
    ...mapActions("categories", [
      "deleteCategory","multiDeleteRecords"
    ]),
    fetchRecords() {
      //       fetchRecords() {
      //   this.table.loading = true;

      //   this.$store
      //     .dispatch("categories/fetchRecords")
      //     .catch(error => {
      //       this.$bus.$emit("showSnackbar", {
      //         text: "خطأ أثناء جلب البيانات",
      //         color: "red darken-3"
      //       });
      //     })
      //     .finally(() => {
      //       this.table.loading = false;
      //     });
      // },
      this.table.loading = true;
      // // console.log(page);
      this.$store
        .dispatch("categories/fetchRecords")
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while getting Data",
            color: "accent",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    // async remove(selectedRecord) {
    //   const confirmMessageParams = [
    //     `حذف المشرف رقم ${selectedRecord.id}!`,
    //     `هل أنت متأكد من حذف المشرف '${selectedRecord.name}'؟`,
    //     { color: "error" }
    //   ];
    //   if (!(await this.$root.$confirm(...confirmMessageParams))) return;

    //   this.$store
    //     .dispatch("admins/delete", { recordId: selectedRecord.id })
    //     .then(() => {
    //       this.$bus.$emit("showSnackbar", {
    //         text: "تم حذف المشرف بنجاح",
    //         color: "success"
    //       });
    //     })
    //     .catch(error => {
    //       this.$bus.$emit("showSnackbar", {
    //         text: "خطأ أثناء حذف المشرف",
    //         color: "accent"
    //       });
    //     });
    // },
    async unblock(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("categories/unblock", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `   تم فك حظر  ${selectedRecord.name_ar}`,
            color: "success",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while unblocking Admin  ",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async block(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("categories/block", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: ` تم حظر   ${selectedRecord.name_ar}`,
            color: "warning",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: " Error while blocking Admin",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteThisCategory(id) {
      this.deleteCategory(id).then(() => {
        this.fetchRecords();
      });
    },
    deleteSelected() {

      const selectedRecords = this.records.filter((item) => item.selected);
      const Ids = selectedRecords.map((item) => item.id);

      if (Ids.length > 0) {
        this.multiDeleteRecords(Ids).then(() => {
          this.fetchRecords();
        });
      } else {
        // You can use a snackbar or any other UI component for this
        console.log('No Categories selected for deletion.');
      }
    },
  },
};
</script>
